import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeSettings } from "./theme.jsx";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { loginRequest, redirectUrl } from "./config/authConfig";
import Home from "./views/Home/Home";
import BatchPage from "./views/Batch/BatchPage"
import Layout from "./views/Layout/Layout";
import { callMsGraph } from './graph';
import { InteractionRequiredAuthError } from "@azure/msal-common";
import ApiConfig from "./PromptAPI/ApiConfig.json";
import { setUserProfileData } from "./state/index.jsx";
import WelcomePage from './views/WelcomePage/WelcomePage.jsx';
import { useNavigate } from "react-router-dom";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mode = useSelector((state) => state.global.mode);
  const userData = useSelector((state) => state.global.userProfileData);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [isUserOnBoarded, setIsUserOnboarded] = useState(false);

  //Registering redirect promise on msal instance for handling of login response
  instance.handleRedirectPromise()
    .then((response) => {
      if (response) {
        window.location.href = redirectUrl+"/home";
        instance.setActiveAccount(response.account);
      }
    })
    .catch(err => {
      console.error(err);
    });

  //call to msal login
  const handleLogin = () => {
    instance.loginRedirect(loginRequest).then((response) => {
    }).catch((e) => {
      console.log(e);
    });
    return true;
  };

  //Graph call to fetch profile details // need revisit this
  const requestProfileData = () => {
    const request = { ...loginRequest, account: instance.getActiveAccount() }

    instance.acquireTokenSilent(request).then(tokenResponse => {
      //call to graph api to fetch profile details
      sessionStorage.setItem('accessToken', tokenResponse.idToken );
      callMsGraph(tokenResponse.accessToken).then((response) => {
          const requestOnboard = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('accessToken'),
           },
            body: JSON.stringify({ name: response.displayName, email:response.mail })
        };
          fetch(ApiConfig.baseurl + ApiConfig.onboardUser + "?Subscription-Key=" + ApiConfig.apiM, requestOnboard)
          .then((res) => res.json())
          .then((userDataReponse) => {
              dispatch(setUserProfileData(userDataReponse));                                        
              setIsUserOnboarded(true);
            }).catch((error) =>{
              handleSignOut();
            })
      });
    }).catch(error => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return instance.acquireTokenRedirect(request)
      }
    });
  }

  useEffect(() => {  
    if (userData === null) {
     requestProfileData();         
    }
  }, [userData, isAuthenticated, isUserOnBoarded]);


  const signInButtonClickHandler = () => {
     handleLogin();
  };

  const signOutButtonClickHandler = () => {
    handleSignOut();
  }

  //call to msal logout
  const handleSignOut = () => {
    const account = instance.getActiveAccount();
    const logoutRequest = {
      account: account,
      postLogoutRedirectUri: instance.getConfiguration().auth.redirectUri,
    };
    instance.logoutRedirect(logoutRequest).then(()=>{
      navigate('/');
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
      {!isAuthenticated && 
            <Route path="/" element={<WelcomePage signIn={signInButtonClickHandler} />} />}
        {isAuthenticated && isUserOnBoarded &&
          <Route element={<Layout signOut={signOutButtonClickHandler} />}>
            {/* <Route path="/" element={<Navigate to="/home" replace />} /> */}
            <Route path="/home" element={<Home />} />
            <Route path="/batch" element={<BatchPage />} />
          </Route>}
      </Routes>
    </ThemeProvider>
  );
}

export default App;
