// Theme
export const themeSettings = (mode) => {
  return {
    components: {
      MuiAlert: {
        styleOverrides: {
          standardSuccess: {
            backgroundColor: 'green',
            color: 'white',
            '& svg': {
              color: 'white',
            },
          },
          standardError: {
            backgroundColor: 'red',
            color: 'white',
            '& svg': {
              color: 'white',
            },
          },
          standardWarning: {
            backgroundColor: 'orange',
            color: 'white',
            '& svg': {
              color: 'white',
            },
          },
          standardInfo: {
            backgroundColor: 'grey',
            color: 'black',
            '& svg': {
              color: 'black',
            },
          }
        }
      },
      MuiButton: {
        styleOverrides : {
          containedBitwiseButton : {   
            position: 'sticky',
            backgroundColor: '#DA291C',
            color: '#F0F0F0',
            fontFamily: 'poppins',
            fontWeight: '300',
            pointerEvents: 'auto',
            '&:hover': {
              backgroundColor: '#B3B3B3',
            },
            '&:disabled': {
              backgroundColor: '#B3B3B3',
              color: '#757575',
              pointerEvents: 'none', 
            },
          },
        },
      },
    },
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: '#1E1E1E',
              light: '#1E1E1E',
            },
            secondary: {
              main: '#1E1E1E',
            },
            font: {
              main: '#F0F0F0',
            },
            background: {
              default: '#000000',
            },
            navbar: {
              default: '#2D3139',
              icons: '#F0F0F0',
            },
            footer: {
              default: '#2E3138',
            },
            sidebar: {
              default: '#202327',
            },
            square: {
              background: '#1E1E1E',
              header: '#2D3139',
            },
            accordion: {
              header: '#2D3139',
              content: '#202327',
            },
            searchbar: {
              background: '#000000',
            },
            textfield: {
              focused: '#F0F0F0'
            },
            // action: {
            //   hover: 'white',
            //   selected: '#DA291C',
            // }
          }
        : {
            // palette values for light mode
            primary: {
              main: '#E8E8E8',
              light: '#E8E8E8',
            },
            secondary: {
              main: '#000000',
              light: '#E8E8E8',
            },
            font: {
              main: '#000000',
            },
            background: {
              default: '#F2F2F2',
              alt: '#000000',
            },
            navbar: {
              default: '#DCDCDC',
              icons: '#636363',
            },
            footer: {
              default: '#E8E8E8',
            },
            sidebar: {
              default: '#E8E8E8',
            },
            square: {
              background: '#FFFFFF',
              header: '#DCDCDC',
            },
            accordion: {
              header: '#DCDCDC',
              content: '#E8E8E8',
            },
            searchbar: {
              background: '#FFFFFF',
            },
            textfield: {
              focused: '#000000'
            },
            // action: {
            //   hover: 'red',
            //   selected: '#DA291C',
            // }
          }),
    },
  };
};

// Style
export const styles = {

  // Typography
  typography: {
    position:'relative', 
    fontFamily: 'inter',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '30px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  
};

export default styles;