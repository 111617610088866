import * as React from 'react';
import { useState, useEffect, useContext, useRef } from 'react';
import { Paper, Dialog, Container, IconButton, Typography, MenuItem, FormControl, Select, useTheme } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { AlertContext } from '../../AlertContext.jsx';
import CodeEditor from './CodeEditor.jsx';
import { userPrompt, header, content, fileUpload, selectPosition, selectForm } from './DialogBoxStyles.jsx';
import styles from '../../theme.jsx';
import { setInputLang, setInputCode, toggleFullscreenInput } from '../../state/index.jsx';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

function CodeEditDialogBox() {
  // Styling
  const theme = useTheme();
  const userPromptStyle = userPrompt();
  const headerStyle = header();
  const contentStyle = content();
  const fileUploadStyle = fileUpload();
  const selectPositionStyle = selectPosition();
  const selectFormStyle = selectForm();

  // Initializations
  const dispatch = useDispatch();
  const { setAlert } = useContext(AlertContext);
  const inputCode = useSelector((state) => state.global.inputCode);
  const { function: functionValue, inputLang: inputLang, outputLang: outputLang } = useSelector((state) => state.global);
  const fileInputRef = useRef(null);
  const [code, setCode] = useState(inputCode);
  const isFullscreenInput = useSelector((state) => state.global.fullscreenInput);
  const extensions = {
    "python": ".py",
    "javascript": ".js,.jsx",
    "java": ".java",
    "pyspark": ".py"
  }

  useEffect(() => {
    setCode(inputCode);
  }, [inputCode]);

  // Handlers
  const handleLanguage = (event) => {
    dispatch(setInputLang(event.target.value));
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  }
  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      try {
        reader.onload = (e) => {
          const contents = e.target.result;
          dispatch(setInputCode(contents));
          setCode(contents);
        };
      }
      catch (error) {
        setAlert({ open: true, severity: 'error', message: 'File upload failed' });
      }
      reader.readAsText(file);
    }
  }

  return (
    <>
      <Dialog 
        open={isFullscreenInput} 
        onClose={() => dispatch(toggleFullscreenInput())} 
        fullWidth  
        PaperProps={{ sx: { ...(userPromptStyle) } }}
      >

        <Paper sx={{...(headerStyle), background: theme.palette.square.header}} square>
          <Container>
            <Typography sx={{...(styles.typography), color: theme.palette.font.main, lineHeight: '7.5vh'}}>Input</Typography>
            <IconButton onClick={handleButtonClick} size="small" className="iconButton" sx={{ ...(fileUploadStyle), fontSize: '17px'}}>
              <FileUploadOutlinedIcon fontSize="small" />
            </IconButton>
            <input 
              type='file' 
              accept={extensions[inputLang]}
              style={{display: 'none'}}
              ref={fileInputRef}
              onChange={handleUpload}
            />
            <FormControl size="small" sx={{...(selectPositionStyle)}}>
              <Select sx={{...(selectFormStyle), color: theme.palette.font.main}} 
                labelId="select-language" id="select-language"
                value={inputLang}
                onChange={handleLanguage}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: '200px',
                      overflow: 'auto',
                    },
                  },
                }}
              >
                
                <MenuItem value={"python"}>Python3</MenuItem>
                <MenuItem value={"java"}>Java</MenuItem>
                <MenuItem value={"javascript"}>JavaScript</MenuItem>
                <MenuItem value={"pyspark"}>PySpark</MenuItem>
              </Select>
            </FormControl>
          </Container>
        </Paper>
        <Paper sx={{...(contentStyle), background: theme.palette.square.background}} square variant="outlined">
          <CodeEditor language={inputLang} code={inputCode} /> 
        </Paper>
      </Dialog>
    </>
    
  );
}

export default CodeEditDialogBox;