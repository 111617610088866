import * as React from 'react';
import { useContext } from 'react';
import { useEffect } from "react";
import { Button, Dialog, DialogTitle, Container, IconButton, Box, Typography  } from "@mui/material";
import { userPrompt } from './DialogBoxStyles';
import { useDispatch,useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { PromptAPIservice, fetchData } from '../../PromptAPI/PromptAPIservice.js';
import { AlertContext } from '../../AlertContext.jsx';

function ConfirmationBox({ open, setOpen, item, handler, handlerArgs, title, message }) {
  // Styling
  const userPromptStyle = userPrompt();
  const userData = useSelector((state) => state.global.userProfileData);

  // Initializations
  const dispatch = useDispatch();
  const promptAPIservice = new PromptAPIservice();
  const { setAlert } = useContext(AlertContext);
  useEffect(() => {
    fetchData(user_id, promptAPIservice, dispatch);
  }, []);
  const user_id = userData.id;

  return (
    <>
      <Dialog 
        open={open} 
        onClose={() => setOpen(false)} 
        PaperProps={{sx: {...(userPromptStyle)}}}
      >

        <DialogTitle sx={{ fontSize: 18, fontFamily: 'poppins', fontWeight: '400', marginTop: '-3px', padding:2, paddingLeft: 3, paddingBottom: 1 }}>{title}
        <IconButton className="iconButton" sx={{ fontSize: '15px',  marginTop: '-3px',float: 'right' }}
            onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
          </DialogTitle>
        
        <Container sx={{fontFamily: 'inter'}}>
          {message}
          
          <Box sx={{display: 'flex', justifyContent: 'center'}}><Button size='small' onClick={() => handler(handlerArgs)} sx={{ margin: '15px', marginTop: '20px', float: 'right'}} type="submit" variant="containedBitwiseButton">Submit</Button></Box>
          
        </Container>
      </Dialog>
    </>
    
  );
}

export default ConfirmationBox;