import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  sidebarOpen: true,
  selectedSets: [],
  function: "code_optimization",
  inputLang: "python",
  outputLang: "python",
  currentPrompt: null,
  inputCode: "",
  outputCode: "",
  userProfileData: null,
  condAccepted: false,
  filteredSets: [],
  fullscreenInput: false,
  fullscreenOutput: false,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    toggleSidebar: (state) => {
      state.sidebarOpen = !state.sidebarOpen;
    },
    toggleSet: (state, action) => {
      const itemID = action.payload.id
      const index = state.selectedSets.findIndex(box => box.id === itemID)
      if (index === -1) {
        state.selectedSets.push(action.payload)
      }
      else {
        state.selectedSets.splice(index, 1)
      }
    },
    unselectSetID: (state, action) => {
      const itemID = action.payload
      const index = state.selectedSets.findIndex(box => box.id === itemID)
      if (index !== -1) {
        state.selectedSets.splice(index, 1)
      }
    },

    clearSelectedSets: (state) => {
      state.selectedSets = []
    },
    setFunction: (state, action) => {
      state.function = action.payload;
      // if (state.function !== 'code_conversion') {
      //   state.outputLang = state.inputLang;
      // } else if (state.function === 'code_documentation') {
      //   state.outputLang = "plaintext";
      // }
      if(state.function === 'code_documentation'){
        state.outputLang = "plaintext";
      }
      else{
        if(state.function !== 'code_conversion'){
          state.outputLang = state.inputLang;
        }
        else {
          state.outputLang = "python"; 
        }
      }
    },
    setInputLang: (state, action) => {
      state.inputLang = action.payload;
      switch (state.function) {
        case 'code_optimization':
          state.outputLang = action.payload;
          break;
        case 'code_documentation':
          state.outputLang = "plaintext";
          break;
        case 'code_generation':
          state.outputLang = action.payload;
          break;
        case 'code_conversion':
          break;
      }
    },
    setOutputLang: (state, action) => {
      switch (state.function) {
        case 'code_optimization':
          break;
        case 'code_documentation':
          state.outputLang = "plaintext";
          break;
        case 'code_generation':
          break;
        case 'code_conversion':
          state.outputLang = action.payload;
          break;
      }
    },
    setPromptValues: (state, action) => {
      state.currentPrompt = action.payload
    },
    setInputCode: (state, action) => {
      state.inputCode = action.payload;
    },
    setOutputCode: (state, action) => {
      state.outputCode = action.payload;
    },
    setUserProfileData: (state, action) => {
      state.userProfileData = action.payload;
    },
    setFilteredSets: (state,action) => {
      state.filteredSets = action.payload;
    },
    updateSelectedGoal: (state, action) => {
      const { setIndex, textIndex, newText } = action.payload;
      state.selectedSets[setIndex].promptText[textIndex] = newText;
    },
    toggleFullscreenInput: (state) => {
      state.fullscreenInput = !state.fullscreenInput;
    },
    toggleFullscreenOutput: (state) => {
      state.fullscreenOutput = !state.fullscreenOutput;
    },
  }
})

export const { 
  setMode, 
  toggleSidebar, 
  toggleSet, 
  unselectSetID,
  setFunction, 
  setInputLang, 
  setOutputLang,
  setPromptValues,
  setInputCode,
  setOutputCode,
  setUserProfileData,
  setCondAccepted,
  setFilteredSets,
  updateSelectedGoal,
  clearSelectedSets,
  toggleFullscreenInput,
  toggleFullscreenOutput,
} = globalSlice.actions;

export default globalSlice.reducer;