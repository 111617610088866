import react, { useState, useEffect } from 'react';
import { Menu, MenuItem, AppBar, Box, Toolbar, Button, useTheme, Stack, Paper, Typography, Tooltip } from '@mui/material';
import { LightModeOutlined, DarkModeOutlined, SettingsOutlined, WarningAmberOutlined, AccountCircle } from '@mui/icons-material';
import lightLogo from "../../assets/lightLogo.svg";
import darkLogo from "../../assets/darkLogo.svg";
import { setMode } from '../../state/index.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { appbar, toolbar, logo } from './NavbarStyles';
import { styled } from '@mui/material/styles';
import BatchPredictionIcon from '@mui/icons-material/BatchPrediction';
import HomeIcon from '@mui/icons-material/Home';
import { useIsAuthenticated } from "@azure/msal-react";

function Navbar(props) {
  // Styling
  const mode = useSelector((state) => state.global.mode);
  const userData = useSelector((state) => state.global.userProfileData);
  const theme = useTheme();
  const appbarStyle = appbar();
  const toolbarStyle = toolbar();
  const logoStyle = logo();
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();

  // Initializations
  const dispatch = useDispatch();
  const [isBatchPage, setIsBatchPage] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const codeFunction = useSelector((state) => state.global.function);
  const [warningMenuOpen, setWarningMenuOpen] = useState(false);
  const limitations = [
    'May occassionally generate incorrect information',
    'May occassionally produce harmful instructions or biased content',
    'Limited knowledge of the world and events after 2021',
  ];
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : theme.palette.navbar.default,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
  }));

  // Handlers

  const handleMenu = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    }
    else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleWarningMenu = () => {
    setWarningMenuOpen(!warningMenuOpen);
  };
  
  useEffect(() => {
    setIsBatchPage(location.pathname !== '/batch');
  }, [location]);

  const routingHandler = (event) => {
    if(isBatchPage) {
      navigate('/batch');
    }
    else {
      navigate('/home');
    }
  }

  return (
    <AppBar sx={{...(appbarStyle), backgroundColor:theme.palette.navbar.default}}>
      <Toolbar sx={toolbarStyle} disableGutters>

        <Box height='32px'>
          
          { mode === 'light' &&
            <img src={lightLogo} style={logoStyle} alt='logo' />
          }
          { mode === 'dark' &&
            <img src={darkLogo} style={logoStyle} alt='logo' />
          }
        </Box>

        <Toolbar sx={{justifyContent: "space-between"}}>
          <Box>

          <Tooltip enterDelay="1000" title="Theme toggle">
          <Button onClick={() => dispatch(setMode())} sx={{borderRadius: 5, color: theme.palette.navbar.icons}}>
          { mode === 'light' &&
            <DarkModeOutlined sx={{height: '21px'}}/>
          }
          { mode === 'dark' &&
            <LightModeOutlined />
          }
          </Button>
          </Tooltip>

          {codeFunction == "code_optimization" && isAuthenticated && <Tooltip enterDelay="1000" title="Batch"><Button onClick={routingHandler} sx={{borderRadius: 5, color: theme.palette.navbar.icons}}>
          { isBatchPage ? (<BatchPredictionIcon />) : (<HomeIcon sx={{height: '21px'}} />)}
          </Button></Tooltip>}

          {isAuthenticated && <Tooltip enterDelay="1000" title="Settings"><Button sx={{borderRadius: 8, color: theme.palette.navbar.icons}}>
            <SettingsOutlined sx={{height: '21px'}}/>
          </Button></Tooltip>}

          <Tooltip enterDelay="1000" title="Limitations">
          <Button
            size="large"
            aria-controls={warningMenuOpen ? 'warning-menu' : undefined}
            aria-haspopup="true"
            sx={{borderRadius: 5, color: theme.palette.navbar.icons}} 
            onClick={handleWarningMenu}
          >
            <WarningAmberOutlined sx={{height: '21px'}}/>
          </Button>
          </Tooltip>

          <Menu
            anchorEl={anchorEl}
            id="warning-menu"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={warningMenuOpen}
            onClose={handleWarningMenu}
            PaperProps={{
              style: {
                maxHeight: '400px',
                width: '300px',
                marginTop: '28px'
              },
            }}
          > 
            <div style={{ textAlign: 'center' }}>
              <WarningAmberOutlined sx={{ fontSize: 20}}/><Typography>Limitations</Typography>
            </div>
            <Stack spacing={1} sx={{ padding: '10px'}}>
              {limitations.map((limitation, index) => (
                <Item key={index}><Typography sx={{ fontSize: '14px' }}>{limitation}</Typography></Item>
              ))}
            </Stack>
          </Menu>
          {isAuthenticated &&  ( 
              <Tooltip enterDelay="1000" title="Account"> 
              <Button
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                sx={{borderRadius: 5, color: theme.palette.navbar.icons}}
              >
                <AccountCircle sx={{height: '21px'}}/>
              </Button>
              </Tooltip>
            )}
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>{userData ? userData.name : "My account"}</MenuItem>
                <MenuItem onClick={props.signOut}>Sign Out</MenuItem>
              </Menu>
          </Box>
        </Toolbar>
      </Toolbar>
  </AppBar>
    
  );
}
export default Navbar;