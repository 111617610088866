import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Outlet } from 'react-router-dom'
import AddPrompt from '../DialogBox/AddPrompt';
import { Box, Drawer, CssBaseline, Divider, Button, Fab } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar } from '../../state/index.jsx';
import PromptSets from './PromptSets';
import RadioButtons from './RadioButtons';
import { drawer, drawerContent } from './SidebarStyles';

const drawerWidth = 260;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

export default function Sidebar() {
  // Styling
  const theme = useTheme();
  const drawerStyle = drawer(drawerWidth);
  const drawerContentStyle = drawerContent();

  // Initializations
  const dispatch = useDispatch();
  const openSidebar = useSelector((state) => state.global.sidebarOpen);
  const [openPrompts, setOpenPrompts] = useState(false);
  const code_function = useSelector((state) => state.global.function);

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        
        <Drawer
          sx={{...(drawerStyle)}}
          //disableScrollLock={true}
          PaperProps={{
            style: {
              backgroundColor: theme.palette.sidebar.default,
              border: 'none',
            }
          }}
          variant="persistent"
          anchor="left"
          open={openSidebar}
        >
          <Box sx={{ ...(drawerContentStyle) }}>
            <RadioButtons />
            <Divider />
            <PromptSets />

            <Box sx={{p: 2, position: 'absolute', bottom: 0, zIndex: 2, width: '100%', background: 'transparent', pointerEvents: 'none' }}>
              <Button variant="containedBitwiseButton" disabled={code_function == "code_documentation"} onClick={() => setOpenPrompts(true)} disableElevation sx={{ left: '48px',}}>Add New Prompt</Button>
            </Box>
            <AddPrompt title={'Add Prompts'} method={'Add'} open={openPrompts} setOpen={setOpenPrompts} />
          </Box>
          
        </Drawer>
        
        <Main open={openSidebar}>
        <Fab sx={{left: '-1.3vw', top: '7vh'}} size="small" color="primary" onClick={() => dispatch(toggleSidebar())} aria-label="add">
        {openSidebar ? (
              <Box sx={{display: 'flex'}}>
                <ChevronLeftIcon />
              </Box>
            ) : (
              <Box sx={{paddingLeft: '16px', display: 'flex'}}>
                <ChevronRightIcon />
              </Box>
            )}
        </Fab>
          <Outlet />
        </Main>
      </Box>
    </div>
  );
}
