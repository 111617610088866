import react from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import WelcomeBody from './WelcomeBody';

function WelcomePage({signIn}) {
  return (
    <div style={{ height: '100vh', overflow: 'hidden' }}>
      <Navbar />
      <WelcomeBody signIn={signIn}/>
      <Footer />
    </div>
  );
}

export default WelcomePage;