import React, { useEffect, useState, useRef } from "react";
import Editor from "@monaco-editor/react";
import { loader } from "@monaco-editor/react";
import * as monaco from "monaco-editor";
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { setInputCode, toggleFullscreenInput, toggleFullscreenOutput } from "../../state/index.jsx";
import FullscreenIcon from '@mui/icons-material/Fullscreen';


function CodeEditor({ code, language, onMount, defaultComment }){
  // Styling
  const mode = useSelector((state) => state.global.mode);

  // Initializers
  if (language === 'pyspark') {
    language = 'python';
  }
  const [value, setValue] = useState(getDefaultValue(language));
  const editorRef = useRef(null);
  const dispatch = useDispatch();

  loader.config({ monaco });

  useEffect(() => {
    setValue(getDefaultValue(language));
  }, [language]);

  // Handlers
  const handleCopy = () => {
    if (editorRef.current) {
      const model = editorRef.current.getModel();
      if (model) {
        const value = model.getValue();
        navigator.clipboard.writeText(value);
      }
    }
  };
  
  const handleCodeChange = (newCode) => {
    if(defaultComment !== 'Output'){
      dispatch(setInputCode(newCode));
    }
  };
  const handleFullscreen = () => {
    if (defaultComment !== 'Output') {
      dispatch(setInputCode(code));
      dispatch(toggleFullscreenInput());
    } else {
      // dispatch(setOutputCode(code));
      dispatch(toggleFullscreenOutput());
    }
    
  }

  function getDefaultValue(language) {
    if (defaultComment) {
      switch (language) {
        case 'python':
          return '# Your results will appear here';
        case 'java':
          return '// Your results will appear here';
        case 'javascript':
          return '// Your results will appear here';
        case 'plaintext':
          return 'Your results will appear here';
        default:
          return 'Your results will appear here';
      }
    } else {
      switch (language) {
        case 'python':
          return '# Remove this and paste code here';
        case 'java':
          return '// Remove this and paste code here';
        case 'javascript':
          return '// Remove this and paste code here';
        default:
          return '# Remove this and paste code here';
      }
    }
  }

  // ResizeObserver loop limit fix
  const OriginalResizeObserver = window.ResizeObserver;
  window.ResizeObserver = function (callback) {
    const wrappedCallback = (entries, observer) => {
      window.requestAnimationFrame(() => {
        callback(entries, observer);
      });
    };
    return new OriginalResizeObserver(wrappedCallback);
  };
  for (let staticMethod in OriginalResizeObserver) {
    if (OriginalResizeObserver.hasOwnProperty(staticMethod)) {
      window.ResizeObserver[staticMethod] = OriginalResizeObserver[staticMethod];
    }
  }
  
  return( 
    <>
      {/* <Tooltip title='Fullscreen'>
        <IconButton size="small" style={{position:'absolute', right:'10px', opacity: 0.4, zIndex:1}} onClick={handleFullscreen}>
          <FullscreenIcon fontSize="medium" />
        </IconButton>
      </Tooltip> */}
      <Tooltip title='Copy code'>
        <IconButton size="small" style={{position:'absolute', top: '5px', right:'10px', opacity: 0.4, zIndex:1}} onClick={handleCopy}>
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      
      <Editor 
        onMount={onMount}
        value ={(code !== '') ? code : value}
        onChange={handleCodeChange}
        position='relative'
        height="100%"
        language={language}
        theme={`vs-${mode}`}
        options={{
          lineDecorationsWidth: 0,
          lineNumbersMinChars: 3,
          minimap: { enabled: false },
          overviewRulerLanes: 0,
          hideCursorInOverviewRuler: true,
          overviewRulerBorder: false,
          scrollbar: {
            verticalScrollbarSize: 7,
            horizontalScrollbarSize: 10,
          },
          readOnly: defaultComment === 'Output', 
        }} />
    </>    
  );
}

export default CodeEditor;
