import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { visuallyHidden } from '@mui/utils';
import { PromptAPIservice } from '../../PromptAPI/PromptAPIservice';
import { ThemeContext } from '@emotion/react';
import { table } from './BatchStyles';
import { useTheme } from "@mui/material";
import FileProgress from "./FileProgress";
import ConfirmationBox from '../../components/DialogBox/ConfirmationBox.jsx';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';
import { AlertContext } from '../../AlertContext.jsx';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

function stableSort(array, comparator, order) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  return order === 'asc' ?
    stabilizedThis.map((el) => el[0]).reverse() :
    stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    label: 'Job Name',
  },
  {
    id: 'time',
    numeric: true,
    label: 'Job Created',
  },
  {
    id: 'status',
    numeric: true,
    label: 'Status',
  },
  {
    id: 'events',
    numeric: true,
    label: 'Events',
  },
  {
    id: 'icons',
    numeric: true,
    label: 'Download',
  },
  {
    id: 'abort',
    numeric: true,
    label: 'Abort',
  }


];

const tableStyle = table();

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;

  const theme = useTheme();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ backgroundColor: theme.palette.background.default }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.id == 'icons' || headCell.id == 'abort' ? 'right' : 'left'}
            padding='normal'
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              fontFamily: 'Inter',
              fontWeight: 'bold',
              fontSize: '12px',
              paddingLeft: headCell.id == 'icons' || headCell.id == 'abort' ? 0 : headCell.id === 'name' ? '15px' : headCell.id === 'time' ? '75px' : '50px',
            }}
          >
            {headCell.id === 'time' ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                reverse={orderBy === headCell.id && order === 'desc'}
                sx={{ fontFamily: 'Inter', fontWeight: 'bold', fontSize: '12px', }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
                <Typography variant="subtitle1" sx={{ fontFamily: 'Inter', fontWeight: 'bold', fontSize: '12px', }}>
                  {headCell.label}
                </Typography>
              )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function BatchHistory() {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('time');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const promptAPIservice = new PromptAPIservice();
  const [rows, setRows] = useState([]);
  const [openAbort, setOpenAbort] = useState(false);
  const [abortItem, setAbortItem] = useState(null);
  const [eventItem, setEventItem] = useState(null);
  const userData = useSelector((state) => state.global.userProfileData);
  const userId = userData.id;

  const { setAlert } = useContext(AlertContext);

  const [openEvents, setOpenEvents] = useState(false);

  const handleAbortClick = (item, event) => {
    event.stopPropagation();
    setOpenAbort(true);
    setAbortItem(item);
  }

  const clickAbortButtonHandler = async function (jobId, event) {
    try {
      const res = await promptAPIservice.abortJob(abortItem.jobId);
      setAlert({ open: true, severity: 'success', message: 'Job Aborted successfully' });
      fetchJobs();
      setOpenAbort(false);
    } catch (error) {
      setAlert({ open: true, severity: 'error', message: 'Error in aborting job' });
    }
  }

  const handleEventInfoClicked = (item, event) => {
    event.stopPropagation();
    setOpenEvents(true);
    setEventItem(item);
  }

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    try {
      const res = await promptAPIservice.jobList(userId);
      setRows(res.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownloadClick = async (jobId, jobName, event) => {
    try {
      const res = promptAPIservice.downloadJob(jobId, jobName);
    }
    catch (error) {
      console.error('Error:', error);
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy), order).slice(
        page * rowsPerPage,
        (page + 1) * rowsPerPage,
      ),
    [rows, order, orderBy, page, rowsPerPage],
  );


  return (
    <Box sx={{ width: '100%' }}>
      <div style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff' }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="small">
            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
          </Table>
        </TableContainer>
      </div>
      <div style={{ maxHeight: '48vh', overflow: 'auto' }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="small">
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    role="checkbox"
                    tabIndex={-1}
                    key={row.name}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ marginLeft: '10px', paddingLeft: '15px' }}
                    >
                      <Typography variant="body1" sx={{ fontFamily: 'Inter', fontSize: '12px', }}>
                        {row.jobName}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1" sx={{ fontFamily: 'Inter', fontSize: '12px', }}>
                        {new Date(row.jobStartTime).toLocaleString('en-US', {
                          day: 'numeric',
                          month: 'long',
                          year: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          second: 'numeric',
                          hour12: true
                        })}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1" sx={{ fontFamily: 'Inter', fontSize: '12px', }}>
                        <Tooltip title={row.status} placement="right" arrow arrowPlacement="right" enterDelay={500}>
                          <IconButton>
                            {row.status == 'COMPLETE' ? <CheckCircleOutlineOutlinedIcon fontSize="small" /> :
                              row.status == 'PENDING' ? <PendingOutlinedIcon fontSize="small" /> :
                                row.status == 'FAILED' ? <CancelOutlinedIcon fontSize="small" /> :
                                  row.status == 'IN_PROGRESS' ? <HourglassTopOutlinedIcon fontSize="small" /> :
                                    row.status == 'PARTIALLY_COMPLETED' ? <UnpublishedOutlinedIcon fontSize="small" /> : <ErrorOutlineOutlinedIcon fontSize="small" />}
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton className="iconButton" sx={{ marginTop: '-5px' }} onClick={(event) => handleEventInfoClicked(row, event)}>
                        <InfoOutlined fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton className="iconButton" sx={{ marginTop: '-5px' }} disabled={row.status != 'COMPLETE' && row.status != 'PARTIALLY_COMPLETED'} onClick={(event) => handleDownloadClick(row.jobId, row.jobName, event)}>
                        <DownloadIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton className="iconButton" sx={{ marginTop: '-5px' }} disabled={row.status != 'PENDING'} onClick={(event) => handleAbortClick(row, event)}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 43 * emptyRows }}>
                  <TableCell colSpan={4} />
                </TableRow>
              )}
              {abortItem
                ? <ConfirmationBox open={openAbort} setOpen={setOpenAbort} item={abortItem} handler={clickAbortButtonHandler} handlerArgs={abortItem.id} title='Abort Job' message={
                  <span>
                    Are you sure you want to abort {' '}
                    <strong>{abortItem.jobName}</strong>?
                  </span>
                } />
                : null
              }

              {eventItem
                ? <FileProgress open={openEvents} setOpen={setOpenEvents} jobId={eventItem.jobId} />
                : null
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
