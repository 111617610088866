import * as React from 'react';
import { useContext } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, List, ListItemButton } from '@mui/material';
import { fonttype, fonttypeHeader, accordionHeader } from './SidebarStyles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSelector, useDispatch } from 'react-redux';
import { toggleSet, unselectSetID } from '../../state/index.jsx';
import { useEffect, useState } from 'react';
import { AlertContext } from '../../AlertContext.jsx';
import { PromptAPIservice, fetchSystemData, fetchData } from '../../PromptAPI/PromptAPIservice.js';
import { useTheme, Accordion, AccordionSummary, AccordionDetails, Typography, IconButton } from '@mui/material';
import AddPrompt from '../DialogBox/AddPrompt';
import ConfirmationBox from '../DialogBox/ConfirmationBox';

export default function SystemPromptSets() {
  // Styling
  const theme = useTheme();
  const fonttypeStyle = fonttype(theme);
  const fonttypeHeaderStyle = fonttypeHeader();
  const accordionHeaderStyle = accordionHeader();
  const userData = useSelector((state) => state.global.userProfileData);

  // Initializations
  const dispatch = useDispatch();
  const data = useSelector((state) => state.api.systemData);
  const codeFunction = useSelector((state) => state.global.function);
  const promptAPIservice = new PromptAPIservice();
  const user_id = userData.id;
  const [openPrompts, setOpenPrompts] = useState(false);
  const [currentTitle, setCurrentTitle] = useState({});
  const [currentGoals, setCurrentGoals] = useState({});
  const selectedItems = useSelector((state) => state.global.selectedSets);
  const [openClone, setOpenClone] = useState(false);
  const [cloneItem, setCloneItem] =  useState(null);
  const { setAlert } = useContext(AlertContext);
  

  useEffect(() => {
    fetchSystemData(user_id, promptAPIservice, dispatch);
  }, []);

  // Handlers
  const handleBoxClick = (item) => {
    const isSelected = selectedItems.includes(item);
    if (isSelected) {
      dispatch(unselectSetID(item.id));
    } else {
      dispatch(toggleSet(item));
    }
  }
  const handleDialogClick = (title, goals, event) => {
    event.stopPropagation();
    setCurrentTitle(title);
    setCurrentGoals(goals);
    setOpenPrompts(true);
  }
  const handleCloneClick = (item, event) => {
    event.stopPropagation();
    setOpenClone(true);
    setCloneItem(item);
  }
  const clickCloneButtonHandler = async function (promptId, event){
    setOpenClone(false);
    try {
      const res = await promptAPIservice.promptClone(promptId, user_id);
      setAlert({ open: true, severity: 'success', message: 'Prompt cloning successful' });
      fetchData(user_id, promptAPIservice, dispatch);
    } 
    catch (error) {
      setAlert({ open: true, severity: 'error', message: 'Prompt cloning failed' });
      console.error('Code cloning failed:', error);
    }
  }

  return (
    <div>
      <Accordion defaultExpanded={true} sx={{boxShadow: 'none'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color: theme.palette.font.main}}  />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ ...(accordionHeaderStyle), backgroundColor: theme.palette.accordion.header}}
        >
          <Typography sx={{...(fonttypeHeaderStyle), color: theme.palette.font.main}}>SYSTEM PROMPTSETS</Typography> 
        </AccordionSummary>
        <AccordionDetails sx={{backgroundColor: theme.palette.accordion.content, padding : 0}}>

          {data
            .filter(item => item.promptActionType === codeFunction)
            .map((item, index) => (
            <React.Fragment key={item.id}>
              <Box sx={{display:'flex', width:'100%', maxWidth: 200, justifyContent: 'flex-start', '&:hover .iconButton': {visibility: 'visible'}}}>
                <List disablePadding>
                    <ListItemButton disableRipple onClick={() => handleBoxClick(item)}
                    sx={{
                      overflow:'hidden', 
                      textOverflow:'ellipsis', 
                      whiteSpace:'nowrap', 
                      width: '260px', 
                      height: '35px',
                      backgroundColor: selectedItems.includes(item) ? theme.palette.action.selected : 'transparent',
                    }}>
                    <Typography sx={{...(fonttypeStyle), color: theme.palette.font.main}}>{item.promptTitle}</Typography>
                    <Box sx={{display:'flex', gap: 0.2, width: '100%', justifyContent: 'flex-end', 
                      '& .iconButton': {
                        visibility: 'hidden',
                      }, }}>
                      <IconButton onClick={(event) => handleDialogClick(item.promptTitle, item.promptText, event)} className="iconButton" size="small" sx={{ fontSize: '15px' }}>
                        <VisibilityIcon fontSize="extra-small"/>
                      </IconButton> 
                      <IconButton className="iconButton" size="small" sx={{ fontSize: '15px' }}
                        onClick={(event) => handleCloneClick(item, event)}>
                        <ContentCopyIcon fontSize="extra-small" />
                      </IconButton>
                    </Box>
                  </ListItemButton>    
                </List>
              </Box>
            </React.Fragment>
          ))}
          <AddPrompt title={'System Prompts'} disabled={true} open={openPrompts} setOpen={setOpenPrompts} currentTitle={currentTitle} currentGoals={currentGoals} system={true} />
          {cloneItem
            ? <ConfirmationBox open={openClone} setOpen={setOpenClone} item={cloneItem} handler={clickCloneButtonHandler} handlerArgs={cloneItem.id} title='Clone Prompt' message= {
              <span>
                Are you sure you want to clone{' '}
                <strong>{cloneItem.promptTitle}</strong>?
              </span>
            }/>
            : null
          }
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
