import React, { useState, useRef, useContext, useEffect } from 'react'
import { Container, Paper, Typography, useTheme, FormControl, Select, MenuItem, IconButton } from '@mui/material';
import styles from '../../theme.jsx';
import { header, content, fileUpload, selectPosition, selectForm } from './WindowStyles.jsx';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CodeEditor from '../CodeEditor/CodeEditor.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { setInputLang, setInputCode } from '../../state/index.jsx';
import { AlertContext } from '../../AlertContext.jsx';
import FullscreenEditorInput from '../CodeEditor/FullscreenEditorInput.jsx';

const Input = () => {
  // Styling
  const theme = useTheme();
  const headerStyle = header();
  const contentStyle = content();
  const fileUploadStyle = fileUpload();
  const selectPositionStyle = selectPosition();
  const selectFormStyle = selectForm();

  // Initializations
  const dispatch = useDispatch();
  const { function: functionValue, inputLang: inputLang, outputLang: outputLang } = useSelector((state) => state.global);
  const fileInputRef = useRef(null);
  const [code, setCode] = useState('');
  const inputCode = useSelector((state) => state.global.inputCode);
  const { setAlert } = useContext(AlertContext);
  const extensions = {
    "python": ".py",
    "javascript": ".js,.jsx",
    "java": ".java",
    "pyspark": ".py"
  }

  useEffect(() => {
    setCode(inputCode);
  }, [inputCode]);
  
  // Handlers
  const handleLanguage = (event) => {
    dispatch(setInputLang(event.target.value));
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  }
  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      try {
        reader.onload = (e) => {
          const contents = e.target.result;
          dispatch(setInputCode(contents));
          setCode(contents);
        };
      }
      catch (error) {
        setAlert({ open: true, severity: 'error', message: 'File upload failed' });
      }
      reader.readAsText(file);
    }
  }
  
  return (
    <div className={`$componentWidthClass`}>
      <Paper sx={{...(headerStyle), left: '2%', background: theme.palette.square.header}} square>
        <Container>
          <Typography sx={{...(styles.typography), color: theme.palette.font.main, lineHeight: '40px'}}>Input</Typography>
          <IconButton onClick={handleButtonClick} size="small" className="iconButton" sx={{ ...(fileUploadStyle), fontSize: '17px'}}>
            <FileUploadOutlinedIcon fontSize="small" />
          </IconButton>
          <input 
            type='file' 
            accept={extensions[inputLang]}
            style={{display: 'none'}}
            ref={fileInputRef}
            onChange={handleUpload}
          />
          <FullscreenEditorInput />
          <FormControl size="small" sx={{...(selectPositionStyle)}}>
            <Select sx={{...(selectFormStyle), color: theme.palette.font.main}} 
              labelId="select-language" id="select-language"
              value={inputLang}
              onChange={handleLanguage}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: '200px',
                    overflow: 'auto',
                  },
                },
              }}
            >
              
              <MenuItem value={"python"}>Python3</MenuItem>
              <MenuItem value={"java"}>Java</MenuItem>
              <MenuItem value={"javascript"}>JavaScript</MenuItem>
              <MenuItem value={"pyspark"}>PySpark</MenuItem>
            </Select>
          </FormControl>
        </Container>
      </Paper>
      <Paper sx={{...(contentStyle), left: '2%', background: theme.palette.square.background}} square variant="outlined">
        <CodeEditor language={inputLang} code={code} /> 
      </Paper>
    </div>
  )
}

export default Input