import * as React from 'react';
import { useContext } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { fonttype, fonttypeHeader, accordionHeader } from './SidebarStyles';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SearchBar from './SearchBar';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleSet, unselectSetID } from '../../state/index.jsx';
import { useTheme, Accordion, AccordionSummary, AccordionDetails, Typography,  List, ListItemButton, Box, IconButton } from '@mui/material';
import { PromptAPIservice, fetchData, setFilteredData } from '../../PromptAPI/PromptAPIservice.js';
import { AlertContext } from '../../AlertContext.jsx';
import DialogBox from '../DialogBox/AddPrompt';
import ConfirmationBox from '../DialogBox/ConfirmationBox';

export default function UserPromptSets() {
  // Styling
  const theme = useTheme();
  const fonttypeStyle = fonttype();
  const fonttypeHeaderStyle = fonttypeHeader();
  const accordionHeaderStyle = accordionHeader();
  const iconButtonStyles = {
    fontSize: '15px',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  };

  // Initializations
  const dispatch = useDispatch();
  const data = useSelector((state) => state.api.data);
  const codeFunction = useSelector((state) => state.global.function);
  const filteredData = useSelector((state) => state.api.filteredData);
  const userData = useSelector((state) => state.global.userProfileData);
  const promptAPIservice = new PromptAPIservice();
  const user_id = userData.id;
  const { setAlert } = useContext(AlertContext);
  const [openPrompts, setOpenPrompts] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openClone, setOpenClone] = useState(false);
  const [currentTitle, setCurrentTitle] = useState({});
  const [currentGoals, setCurrentGoals] = useState({});
  const [deleteItem, setDeleteItem] = useState(null);
  const [cloneItem, setCloneItem] =  useState(null);
  const [editItem, setEditItem] =  useState(null);
  const selectedItems = useSelector((state) => state.global.selectedSets);

  useEffect(() => {
    fetchData(user_id, promptAPIservice, dispatch);
  }, []);

  // Handlers
  const handleBoxClick = (item) => {
    const isSelected = selectedItems.includes(item);
    if (isSelected) {
      dispatch(unselectSetID(item.id));
    } else {
      dispatch(toggleSet(item));
    }
  }
  const handleEditClick = (item, event) => {
    event.stopPropagation();
    setCurrentTitle(item.promptTitle);
    setCurrentGoals(item.promptText);
    setEditItem(item);
    setOpenPrompts(true);
  }
  const handleDeleteClick = (item, event) => {
    event.stopPropagation();
    setOpenDelete(true);
    setDeleteItem(item);
  }
  const handleCloneClick = (item, event) => {
    event.stopPropagation();
    setOpenClone(true);
    setCloneItem(item);
  }
  const clickCloneButtonHandler = async function (promptId, event){
    setOpenClone(false);
    try {
      const res = await promptAPIservice.promptClone(promptId, user_id);
      setAlert({ open: true, severity: 'success', message: 'Prompt cloning successful' });
      fetchData(user_id, promptAPIservice, dispatch);
    } 
    catch (error) {
      setAlert({ open: true, severity: 'error', message: 'Prompt cloning failed' });
      console.error('Code cloning failed:', error);
    }
  }
  
  const clickDeleteButtonHandler = async function (promptId) {
      try {
        setOpenDelete(false);
        const res = await promptAPIservice.promptDelete(promptId);
        dispatch(unselectSetID(promptId));
        setAlert({ open: true, severity: 'success', message: res.data });
        fetchData(user_id, promptAPIservice, dispatch);
      }
      catch (error) {
        setAlert({ open: true, severity: 'error', message: 'Prompt deletion failed' });
        console.error('Code deletion failed:', error);
      }
  };

  
  return (
    <div>
      <Accordion defaultExpanded={true} sx={{boxShadow: 'none'}}>
      <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color: theme.palette.font.main}}  />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ ...(accordionHeaderStyle), backgroundColor: theme.palette.accordion.header}}
        >
          <Typography noWrap={true} sx={{...(fonttypeHeaderStyle), color: theme.palette.font.main}}>USER PROMPTSETS</Typography> 
     
        </AccordionSummary>
        <AccordionDetails sx={{backgroundColor: theme.palette.accordion.content, padding: 0 }}>
          <SearchBar data={data} dispatchTo={setFilteredData} placeholder="Search Prompts"/>
          {filteredData
            .filter(item => item.promptActionType === codeFunction)
            .map((item, index) => (
            <React.Fragment key={item.id}>
              <Box sx={{display:'flex', width:'100%', maxWidth: 200, justifyContent: 'flex-start', '&:hover .iconButton': {visibility: 'visible'}}}>
                <List disablePadding>
                  <ListItemButton disableRipple onClick={() => handleBoxClick(item)} sx={{
                    whiteSpace: 'nowrap',
                    height: '35px',
                    width: '260px',
                    display: 'flex',  
                    justifyContent: 'space-between', 
                    alignItems: 'center', 
                    backgroundColor: selectedItems.some(selectedItem => selectedItem.id === item.id)
                      ? theme.palette.action.selected
                      : 'transparent',
                    '&:hover': {
                      '& .hoverBox': {
                        maxWidth: '70%',
                      },
                    },
                    }}>
                    <Box className="hoverBox" sx={{ maxWidth: '100%', display: 'flex', gap: 0.2, justifyContent: 'flex-end', }}>
                      <Typography noWrap={true}
                        sx={{
                          ...(fonttypeStyle),
                          color: theme.palette.font.main,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}>
                        {item.promptTitle}</Typography>
                    </Box>
                    <Box sx={{display:'flex', gap: 0.2, width: '100%', justifyContent: 'flex-end', 
                      '& .iconButton': {
                        visibility: 'hidden',
                      }, }}>
                      <IconButton className="iconButton" size="small" sx={{
                        ...iconButtonStyles,
                        right: '3.9rem',
                        }}
                        onClick={(event) => handleEditClick(item, event)}>
                        <EditIcon fontSize="extra-small" />
                      </IconButton>
                      <IconButton className="iconButton" size="small" sx={{
                          ...iconButtonStyles,
                          right: '2.2rem',
                        }}
                        onClick={(event) => handleCloneClick(item, event)}>
                        <ContentCopyIcon fontSize="extra-small" />
                      </IconButton>
                      <IconButton className="iconButton" size="small" sx={{
                        ...iconButtonStyles,
                        right: '0.5rem',
                        }}
                        onClick={(event) => handleDeleteClick(item, event)}>
                        <DeleteOutlineOutlinedIcon fontSize="extra-small" />
                      </IconButton>
                    </Box>
                  </ListItemButton>    
                </List>
              </Box>
            </React.Fragment>
          ))}   
          {editItem ? <DialogBox title={'Edit Prompts'} method={'Edit'} disabled={false} open={openPrompts} setOpen={setOpenPrompts} currentTitle={currentTitle} currentGoals={currentGoals} promptId={editItem.id} system={false}/> 
            : null
          }
          {deleteItem 
            ? <ConfirmationBox open={openDelete} setOpen={setOpenDelete} item={deleteItem} handler={clickDeleteButtonHandler} handlerArgs={deleteItem.id} title='Delete Prompt' message={
              <span>
                Are you sure you want to delete{' '}
                <strong>{deleteItem.promptTitle}</strong>?
              </span>
            }/>    
            : null
          }
          {cloneItem
            ? <ConfirmationBox open={openClone} setOpen={setOpenClone} item={cloneItem} handler={clickCloneButtonHandler} handlerArgs={cloneItem.id} title='Clone Prompt' message= {
              <span>
                Are you sure you want to clone{' '}
                <strong>{cloneItem.promptTitle}</strong>?
              </span>
            }/>
            : null
          }
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
