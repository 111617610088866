import react from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Box, Typography, Button, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

function WelcomeBody({signIn}) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleAgree = () => {
    navigate('/home');
  }


  return (
    <div style={{ marginTop: '64px' }}> 
        <h1 style={{textAlign: 'center' , fontFamily:'poppins', fontSize: '25px', marginTop: '100px'}}>Introducing Bitwizard</h1>
        <Box sx={{
          border: `2px solid ${theme.palette.primary.main}`,
          borderRadius: '8px',
          padding: '16px',
          width: '700px',
          margin: 'auto',
          marginTop : '25px',
          textAlign: 'center',
          backgroundColor: theme.palette.primary.main,
        }}>
            <Typography
            variant="h5"
            align="center"
            sx={{ fontWeight: 'bold', marginBottom: '16px', fontFamily:'poppins', fontSize: '20px'}}
            >
              Notice
            </Typography>
            <Typography sx={{ marginBottom: '16px', fontFamily:'poppins', fontSize: '15px'}}>By accessing and using Bitwizard, you agree that you will not submit any personal data 
                or personally identifiable information, personal health data, or other sensitive or regulated data 
                into Bitwizard and that GPT may store, monitor, track or inspect data that is accessible by Bitwizard.
            </Typography>
            <Typography sx={{ fontFamily:'poppins', fontSize: '15px'}}>
                We take information security very seriously, and expect all employees to comply with our policies 
                in order to protect our organization from potential risks. Any violations of our policies will 
                result in disciplinary action. 
            </Typography>
            <hr style={{ borderTop: `1px solid` }} />
            <Typography sx={{ fontFamily:'poppins', fontSize: '15px'}}> Note: The messages being sent to GPT are logged for analytical purposes</Typography>
            <Link to="/home">
                <Button variant="containedBitwiseButton" onClick={signIn} disableElevation sx={{ marginTop : '20px'}}>I agree</Button>
            </Link>
        </Box>
    </div>
  );
}

export default WelcomeBody;