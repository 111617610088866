import Input from "../../components/Windows/Input";
import Output from "../../components/Windows/Output";
import { Box } from "@mui/material";
import SelectedSetWindow from "../../components/Windows/SelectedSetWindow";

const Home = () => {
	return (
		<div>
			<Box display="flex" position="relative" top="50px">
				<Input />
				<SelectedSetWindow />
				<Output />
			</Box>
		</div>)
}

export default Home;