export const userPrompt = () => ({
  width: "100%",
  minWidth: "90%",
  height: "90%",
  minHeight: "90%",
  maxHeight: "90%",
});

export const header = () => ({
  position:'absolute',
  width: '100%',
  zIndex: 1,
  border: 'none',
  justifyContent: 'center',
  borderRadius: '5px 5px 0px 0px',
});

export const content = () => ({
  position:'absolute',
  top: '7vh',
  height: '79vh', 
  width: '100%',
  zIndex: 2, 
  border: 'none',
});

export const fileUpload = () => ({
  position: 'absolute', 
  top: '1.25vh', 
  left: '75%',
});

export const selectPosition = () => ({
  position: 'absolute', 
  top: '1.35vh', 
  left: '79%',
  width: '20%',
});

export const selectForm = () => ({
  height: '30px', 
  backgroundColor: '#5C9DFF1A', 
  borderRadius: '6px',
  '& .MuiSelect-select': {
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});

export const fontsize = () => ({
  '& .MuiFormControlLabel-label': {
    fontSize:'0.9rem',
    whiteSpace: 'nowrap',
  },
  margin: '-6px -20px',
});

export const fonttype = () => ({
  fontFamily: 'inter',
  fontSize: '14px',
  fontWeight: '300',
  lineHeight: '15px',
  letterSpacing: '0em',
  textAlign: 'left',
});

export const fonttypeHeader = () => ({
  fontFamily: 'inter',
  fontSize: '14px',
  fontWeight: '400',
  letterSpacing: '0em',
  textAlign: 'left',
});

export const accordionHeader = () => ({
  flexDirection: 'row-reverse',
  minHeight: '30px',
  maxHeight: '30px',
  '&.Mui-expanded': {
    minHeight: '30px',
    maxHeight: '30px',
  },
  '.MuiAccordionSummary-content': {
    margin: '0px',
    '&.Muiexpanded': {
      margin: '0px',
    },
  },
});

export const rowBox = () => ({
  display:'flex', 
  justifyContent:'space-between', 
  alignItems:'center', 
  width:'100%'
});

export const textBox = () => ({
  overflow:'hidden', 
  textOverflow:'ellipsis', 
  whiteSpace:'nowrap', 
  width: '100%'
});