
export const radio = () => ({
    color: '#5B6770',
    padding: 0,
    '&.Mui-checked': {
      color: '#5B6770',
      '& .MuiSvgIcon-root': {
        fill: "#5B6770",
        padding: 0,
      },
    },
    transform: 'scale(0.8)',
  });

  export const paper = () => ({
    marginTop: '0.05%',
    marginLeft: '10%',
    width: '80%', 
    height: '75vh', 
    padding: '10px 20px 20px 20px',     
  });

  export const checkbox = () => ({
    
    color:'#5B6770',
    padding: 0,
    '&.Mui-checked': {
      color: "#5B6770",
      '&.MuiCheckbox-root': {
        padding: 0,
        color:'#5B6770',
      },
    },
    transform: 'scale(0.8)',
  });


  export const codeView = () => ({
    minWidth: "70%",
    minHeight: "70%",
  });

  export const row = () => ({
    fontFamily: 'inter',
    fontSize: '15px',
    lineHeight: '30px',
    whiteSpace: 'nowrap',
    display:'flex',
    height: '30px',
    borderBottom: '1px solid grey',
    justifyContent: 'space-between'
  });

  export const rowFiles = () => ({
    fontFamily: 'inter',
    fontSize: '15px',
    lineHeight: '30px',
    width: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    height: '30px',
    width: '100%',
    borderBottom: '1px solid grey',
  });


  export const selectFiles = () => ({
    width: '80%', 
    marginLeft: '10%', 
    borderRadius: '5px', 
    paddingLeft: 2, 
    border: '1px solid rgba(0, 0, 0, 0.12)', 
    minHeight: '45vh',  
    maxHeight: '45vh', 
    overflow: 'auto',
    color: '#6C7075',
  });
  
  export const input = () => ({
    minWidth: '300px', 
    borderRadius: '5px', 
    paddingLeft: 2, 
    border: '1px solid rgba(0, 0, 0, 0.12)', 
    minHeight: '45vh',  
    maxHeight: '45vh', 
    overflowY: 'auto',
    color: '#6C7075',
    marginRight: '10px'
  });

  export const output = () => ({
    minWidth: '300px', 
    borderRadius: '5px', 
    paddingLeft: 2, 
    border: '1px solid rgba(0, 0, 0, 0.12)', 
    minHeight: '45vh',
    maxHeight: '45vh',   
    overflowY: 'auto',
    color: '#6C7075',
    
  });

  export const sourceTarget = () => ({
    display:'flex',
    justifyContent: 'space-between',
    maxWidth: '80%',
    marginLeft: '10%',
    minHeight: '45vh', 
    maxHeight: '45vh',  
  });

  export const table = () => ({
    width: '90%',
    marginLeft: '5%',
    //border: '1px solid grey',
  });