import { Container, TextField, useTheme } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilteredData } from '../../PromptAPI/PromptAPIservice.js';

export default function SearchBar({ data, dispatchTo, placeholder }) {
  // Styling
  const theme = useTheme();

  // Initializations
  const dispatch = useDispatch();
  
  const [searchString, setSearchString] = useState("");

  // Handlers
  const handleChange = (event) => {
    setSearchString(event.target.value);

    const filteredData = data.filter(item =>
      item.promptTitle.toLowerCase().includes(event.target.value.toLowerCase())
    )
    dispatch(dispatchTo(filteredData))
  };

  return (
    <Container sx={{paddingBottom:'15px'}}>
      <TextField
        id="search"
        type="search"
        placeholder={placeholder}
        autoComplete="off"
        value={searchString}
        onChange={handleChange}
        sx={{
          '& .MuiInputBase-root': {
            height: '28px',
            width: '225px',
            transform: 'translate(-10px, 8px)',
            background: theme.palette.searchbar.background,
            fontSize: '13px',
          },
          '& .MuiInputLabel-root': {
            transform: 'translate(-2px, 14px)',
          },
        }}
      />
    </Container>
  );
}