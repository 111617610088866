import React, { useState, useContext } from 'react'
import { IconButton, Paper, Typography, useTheme, Button, Box, CircularProgress } from '@mui/material';
import styles from '../../theme.jsx';
import { header, content, } from './WindowStyles.jsx';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { AlertContext } from '../../AlertContext.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { PromptAPIservice } from '../../PromptAPI/PromptAPIservice.js';
import { setOutputCode, updateSelectedGoal } from '../../state/index.jsx';
import 'react-edit-text/dist/index.css';
import SelectedSet from './SelectedSet.jsx';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import ConfirmationBox from '../DialogBox/ConfirmationBox.jsx';
import { unselectSetID } from '../../state/index.jsx'

const SelectedSetWindow = () => {
  // Styling
  const theme = useTheme();
  const headerStyle = header();
  const contentStyle = content();

  // Initializations
  const dispatch = useDispatch();
  const promptAPIservice = new PromptAPIservice();
  const { setAlert } = useContext(AlertContext);
  const code = useSelector(state => state.global.inputCode);
  const selectedSets = useSelector(state => state.global.selectedSets);
  const codeFunction = useSelector(state => state.global.function);
  const inputLang = useSelector(state => state.global.inputLang);
  const userData = useSelector((state) => state.global.userProfileData);
  const [isLoading, setIsLoading] = useState(false);
  const [openRefresh, setOpenRefresh] = useState(false);

  // Handlers
  const handleClick = async () => {
    setIsLoading(true);

    if (code.trim() === '') {
      setAlert({ open: true, severity: 'error', message: 'Code cannot be empty' });
      setIsLoading(false);
      return;
    }    
    const data = {
      language: inputLang,
      code: code,
      prompts: selectedSets.filter(item => item.promptActionType === codeFunction),
      user: userData,
      action: codeFunction
    }
    
    if (codeFunction === "code_optimization") {
      try {
        const res = await promptAPIservice.codeOptimize(data);
        if (res.data.successful === "Yes") {
          dispatch(setOutputCode(res.data.content.extracted_code));
          setAlert({ open: true, severity: 'success', message: 'Code optimization successful' });
        }
        else if (res.data.successful === "Partial") {
          dispatch(setOutputCode("Warning Message: \n" + res.data.error_warning.warning_message + "\nCode: \n" + res.data.content.extracted_code));
          setAlert({ open: true, severity: 'success', message: 'Code optimization successful with warnings' });
        }
        else {
          dispatch(setOutputCode(res.data.error_warning.error_message));
          setAlert({ open: true, severity: 'error', message: 'Code optimization unsuccessful' });
        }
      }
      catch (error) {
        setAlert({ open: true, severity: 'error', message: 'Prompt optimization failed' });
        console.error('Code optimization failed:', error);
      }
      finally {
        setIsLoading(false);
      }
    }

    if (codeFunction === "code_documentation") {
      try {
        const res = await promptAPIservice.codeDocumentation(data);
        dispatch(setOutputCode(res.data.content.response));
        setAlert({ open: true, severity: 'success', message: 'Code documentation successful' });
      }
      catch (error) {
        setAlert({ open: true, severity: 'error', message: 'Code documentation failed' });
        console.error('Code documentation failed:', error);
      }
      finally {
        setIsLoading(false);
      }
    }
  };

  const handleRefreshClick = (event) => {
    setOpenRefresh(true);
  }

  const clickRefreshButtonHandler = (event) => {
    setOpenRefresh(false);
    selectedSets.map((item) => {
      dispatch(unselectSetID(item.id));
    })
  }

  const displaybutton = !((code.trim() !== '') && (selectedSets.length > 0) && !isLoading);


  let m = selectedSets.length > 0 ? '20px' : '0px'

  return (
    <div>
      <Paper sx={{ ...(headerStyle), left: '38%', width: '23.5%', background: theme.palette.square.header }} square variant="outlined">
        <Box display='flex' justifyContent={selectedSets.length > 0 ? 'space-between' : 'center'} alignItems='center' width='100%'>
          <Typography sx={{ ...(styles.typography), color: theme.palette.font.main, lineHeight: '40px', marginLeft: m }}>
            Selected Prompts
          </Typography>
          {selectedSets.length > 0 && (
            <IconButton size="small" className="iconButton" sx={{ fontSize: '1px', marginRight: '10px' }}
              onClick={(event) => handleRefreshClick(event)}>
              <ReplayOutlinedIcon fontSize="small" />
            </IconButton>
          )}
          <ConfirmationBox open={openRefresh} setOpen={setOpenRefresh} handler={clickRefreshButtonHandler} title='Reset selected prompts' message={
            <span>
              Are you sure you want to reset all the <strong>Selected Promptsets</strong>?
              </span>
          } />
        </Box>
      </Paper>
      <Paper sx={{ ...(contentStyle), left: '38%', width: '23.5%', background: theme.palette.square.background }} square variant="outlined">
        <Box sx={{ height: '87.5%', overflowY: 'auto' }}>
          <SelectedSet />
          <Box sx={{ p: 1, position: 'absolute', bottom: 0, mb: 1, zIndex: 2, width: '100%', paddingLeft: '10%', left: '10%' }}>
            <Button variant="containedBitwiseButton"
              onClick={handleClick}
              disabled={displaybutton}
              disableElevation
              sx={{
                width: '70%'
              }}
            >
              Execute{isLoading ? <CircularProgress size={20} sx={{ marginLeft: 1 }} /> : <SettingsSuggestIcon fontSize='small' sx={{ marginLeft: 1 }} />}
            </Button>
          </Box>
        </Box>
      </Paper>
    </div>
  )
}

export default SelectedSetWindow

