import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useContext } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Divider, Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Dialog, DialogTitle, TextField, Container, useTheme, Fab, IconButton, CircularProgress } from "@mui/material";
import { radio, selectFiles, row, checkbox, input, output, sourceTarget, rowFiles, paper } from './BatchStyles';
import { AlertContext } from '../../AlertContext.jsx';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import HistoryIcon from '@mui/icons-material/History';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import SelectedSet from "../../components/Windows/SelectedSet";
import { PromptAPIservice } from '../../PromptAPI/PromptAPIservice';
import { useDispatch, useSelector } from 'react-redux';
import BatchHistory from './BatchHistory';
import { clearSelectedSets } from '../../state/index.jsx';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';

const steps = ['Configuration', 'Select Files', 'Select Prompts'];

export default function HorizontalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const [source, setSource] = React.useState('');
  const [target, setTarget] = React.useState('');

  const [sourceFolder, setSourceFiles] = React.useState();
  const [targetFolder, setTargeFiles] = React.useState();

  const selectedSets = useSelector(state => state.global.selectedSets);

  const [viewCode, setViewCode] = React.useState(false);

  const [selectAll, setSelectAll] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);

  const [isHistoryOpen, setIsHistoryOpen] = React.useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();

  const dispatch = useDispatch();

  const { setAlert } = useContext(AlertContext);

  const radioStyle = radio();

  const checkboxStyle = checkbox();

  const selectFilesStyle = selectFiles();

  const rowStyles = row();

  const inputStyles = input();

  const outputStyles = output();

  const sourceTargetDIV = sourceTarget();

  const rowFilesStyles = rowFiles();

  const paperStyles = paper();

  var promptIds = '';

  const extensions = {
    "python": ".py",
    "javascript": ".js,.jsx",
    "java": ".java",
    "pyspark": ".py"
  }

  const userData = useSelector((state) => state.global.userProfileData);
  const userId = userData.id;

  const promptAPIservice = new PromptAPIservice();
  const mode = useSelector((state) => state.global.mode);

  const handleSourceChange = (event) => {
    setSource(event.target.value);
  };

  const handleSourceFolderChange = (event) => {
    setSourceFiles(event.target.files);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (source && sourceFolder) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    else {
      setAlert({ open: true, severity: 'error', message: "Please select all the fields" });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    if (sourceFolder) {
      setSelectedItems(Object.values(sourceFolder));
    }
  }, [sourceFolder]);

  const clickSubmitButtonHandler = async (promptIds, files) => {
    setIsLoading(true);
    selectedSets.map((promptID) => {
      promptIds += promptID.id + ',';
    });

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    formData.append('userId', userId);
    formData.append('promptIds', promptIds);

    try {
      const res = await promptAPIservice.jobCreate(formData);
      setAlert({ open: true, severity: 'success', message: 'Job creating successful' });
      setActiveStep(0);
      setSource('');
      setSourceFiles();
      dispatch(clearSelectedSets());
    }
    catch (error) {
      setAlert({ open: true, severity: 'error', message: 'Job creating failed' });
      console.error('Job creating failed:', error);
    }
    finally {
      setIsLoading(false);
    }

  }

  const handleSelectAll = () => {
    const allItems = Object.values(sourceFolder);
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(allItems);
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (event, file) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, file]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item !== file)
      );
    }
    setSelectAll(false);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleRefreshHistory = () => {
    setIsHistoryOpen(false);
    setTimeout(() => { setIsHistoryOpen(true) }, 1);
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <>
            <Box sx={{ height: '45vh', marginLeft: '50px' }}>

              <table>

                <tr>
                  <td><Typography sx={{ fontFamily: 'inter' }}>Source Language: </Typography></td>
                  <td>
                    <FormControl fullWidth sx={{
                      m: 1, width: '30vw', marginLeft: '20%', '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: theme.palette.textfield.focused,
                        }
                      },
                      '& .MuiInputLabel-outlined.Mui-focused': {
                        color: theme.palette.textfield.focused,
                      }
                    }} size="small">
                      <InputLabel id="demo-select-small-label">Source</InputLabel>
                      <Select labelId="demo-select-small-label" id="demo-select-small" value={source} label="Source"
                        onChange={handleSourceChange}>

                        <MenuItem value={"python"}>Python3</MenuItem>
                        <MenuItem value={"java"}>Java</MenuItem>
                        <MenuItem value={"javascript"}>JavaScript</MenuItem>
                        <MenuItem value={"pyspark"}>PySpark</MenuItem>

                      </Select>
                    </FormControl>
                  </td>
                </tr>

                <tr>
                  <td><Typography sx={{ fontFamily: 'inter' }}>Folder Name: </Typography></td>
                  <td>

                    <FormControl fullWidth sx={{
                      m: 1, width: '30vw', marginLeft: '20%',
                      backgroundColor: 'transparent',
                      '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                          borderColor: theme.palette.textfield.focused,
                        }
                      },
                      '& .MuiInputLabel-outlined.Mui-focused': {
                        color: theme.palette.textfield.focused,
                      }
                    }} size="small">
                      {sourceFolder
                        ? <InputLabel id="demo-select-small-label" shrink={false}>Files selected</InputLabel>
                        : <InputLabel id="demo-select-small-label" shrink={false}>Select Files below...</InputLabel>
                      }
                      <TextField disabled labelId="demo-select-small-label" id="demo-select-small" size="small"
                        onChange={handleSourceChange} />
                    </FormControl>
                  </td>
                </tr>

              </table>

              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginRight: '25px' }}>
                <Button variant="containedBitwiseButton" component="label">
                  Browse Files
                <input type="file" accept={extensions[source]} onChange={handleSourceFolderChange} style={{ display: 'none' }} id="ctrl" multiple />
                </Button>
              </div>

            </Box>
          </>
        );


      case 1:
        if (!source || !sourceFolder) {
          setAlert({ open: true, severity: 'error', message: "Please complete step 1" });
          setActiveStep(0);
          return;
        }
        return (
          <>
            <Box sx={{ ...selectFilesStyle, backgroundColor: theme.palette.footer.default, color: theme.palette.text.primary }}>
              <Box style={{ position: 'sticky', top: 0, backgroundColor: theme.palette.footer.default, color: theme.palette.text.primary, zIndex: "100" }}>
                <Box sx={{ ...rowFilesStyles }}>
                  <Checkbox checked={selectAll} onChange={handleSelectAll} sx={{ ...checkboxStyle }} /> <span style={{ fontWeight: '600' }}>Select All</span>
                </Box>
              </Box>
              {Object.entries(sourceFolder).map(([key, value]) => (
                <Box sx={{ ...rowFilesStyles }}>
                  <Checkbox checked={selectedItems.includes(value)}
                    onChange={event => handleCheckboxChange(event, value)}
                    sx={{ ...checkboxStyle }} />
                  {value.name}
                  <br></br>
                </Box>
              ))}
            </Box>

          </>
        );


      case 2:
        if (!source || !sourceFolder) {
          setAlert({ open: true, severity: 'error', message: "Please complete step 1" });
          setActiveStep(0);
          return;
        }
        if (selectedItems.length == 0) {
          setAlert({ open: true, severity: 'error', message: "Please select atleast one file" });
          setActiveStep(1);
          return;
        }
        return (
          <>
            <Box sx={{ width: '80%', maxWidth: '80%', marginLeft: '10%', height: '45vh', overflowY: 'auto' }}>
              {selectedSets.length > 0
                ? <SelectedSet method={'batch'} />
                : <Typography sx={{ fontFamily: 'inter', marginTop: '15%', textAlign: 'center' }}>Please select prompt sets from the sidebar</Typography>}
            </Box>
          </>
        );
      default:
        return "unknown step";
    }
  }

  return (

    <Box sx={{ width: '100%' }}>

      <Paper sx={{ ...paperStyles }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontFamily: 'poppins', paddingBottom: 1, paddingLeft: 1 }}>Batch</Typography>
          <Box sx={{ marginLeft: 'auto', marginRight: '20px' }}>
            {isHistoryOpen ?
              <IconButton className="iconButton" sx={{ marginTop: '-5px' }}>
                <RefreshIcon fontSize="small" onClick={handleRefreshHistory} />
              </IconButton> : <></>}
            <IconButton className="iconButton" sx={{ marginTop: '-5px' }} onClick={() => setIsHistoryOpen(!isHistoryOpen)}>
              {!isHistoryOpen ? <HistoryIcon fontSize="small" /> : <CloseIcon fontSize="small" />}
            </IconButton>
          </Box>
        </Box>
        <Divider sx={{ marginBottom: '15px' }}></Divider>

        {isHistoryOpen ? (
          <BatchHistory />
        ) : (
            <>
              <Stepper nonLinear sx={{
                width: '80%', marginLeft: '10%', '& .MuiStepLabel-root .Mui-completed': {
                  color: '#DA291C',
                },
                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                {
                  color: 'theme.palette.font.main',
                },
                '& .MuiStepLabel-root .Mui-active': {
                  color: theme.palette.textfield.focused,
                },
                '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                {
                  color: theme.palette.font.main,
                },
                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                  fill: mode == 'dark' ? 'black' : '#F0F0F0',
                },
              }} activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};

                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} onClick={handleStep(index)} sx={{ cursor: 'pointer' }} {...stepProps} >
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>


              <React.Fragment>
                <Typography sx={{ fontFamily: 'inter', mt: 2, mb: 1 }}>{getStepContent(activeStep)}</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'space-evenly' }}>
                  <Button disableElevation sx={{ backgroundColor: '#DA291C', color: '#F0F0F0', fontFamily: 'poppins', fontWeight: '300', pointerEvents: 'auto' }} variant="contained" disabled={activeStep === 0} onClick={handleBack}>
                    <NavigateBeforeOutlinedIcon></NavigateBeforeOutlinedIcon>
                  </Button>

                  {activeStep == 2 && <Button disabled={isLoading || selectedSets.length == 0} disableElevation sx={{ backgroundColor: '#DA291C', color: '#F0F0F0', fontFamily: 'poppins', fontWeight: '300', pointerEvents: 'auto', marginLeft: '34%' }} onClick={() => clickSubmitButtonHandler(promptIds, selectedItems)} variant="contained">
                    Execute Batch{isLoading ? <CircularProgress size={20} sx={{ marginLeft: 1 }} /> : <SettingsSuggestIcon fontSize='small' sx={{ marginLeft: 1 }} />}
                  </Button>}
                  <Box sx={{ flex: '1 1 auto' }} />

                  <Button disableElevation sx={{ backgroundColor: '#DA291C', color: '#F0F0F0', fontWeight: '300', pointerEvents: 'auto', }} variant="contained" disabled={activeStep === 2} onClick={handleNext}>
                    <NavigateNextOutlinedIcon></NavigateNextOutlinedIcon>
                  </Button>

                </Box>
              </React.Fragment>
            </>
          )}
      </Paper>
    </Box>
  );
}