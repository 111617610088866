const location = window.location.origin;

export const msalConfig = {
    auth: {
      clientId: "1dd292e4-d46e-4110-ad9e-ffe621404b6c",
      authority:
        "https://login.microsoftonline.com/950bde67-19db-467b-9a41-f80b7cf24ecb", 
      redirectUri: location,      
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
    scopes: ["User.Read"],
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  };

  export const redirectUrl = location;
  