import React from 'react';
import { Box } from "@mui/material";
import Batch from "../../components/Batch/Batch"

const BatchPage = () => {
    return (
        <div>
            <Box position="relative" top="7vh">
                <Batch />
			</Box>
        </div>
    )
}

export default BatchPage
