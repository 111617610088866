import React from 'react'
import { IconButton, Typography, useTheme, Accordion, AccordionSummary, AccordionDetails, Box, CircularProgress, Tooltip } from '@mui/material';
import { fonttype, fonttypeHeader, accordionHeader, rowBox, textBox } from './WindowStyles.jsx';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { unselectSetID } from '../../state/index.jsx'
import { setOutputCode, updateSelectedGoal } from '../../state/index.jsx';
import { EditText } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .editinput {
    background-color: ${props => props.bgColor};
  }
`;

function SelectedSet({ method }){
    // Styling
    const theme = useTheme();
    const fonttypeStyle = fonttype();
    const fonttypeHeaderstyle = fonttypeHeader();
    const accordionHeaderStyle = accordionHeader();
    const rowBoxStyle = rowBox();
    const textBoxStyle = textBox();

    // Initializations
    const dispatch = useDispatch();
    const selectedSets = useSelector(state => state.global.selectedSets);
    const codeFunction = useSelector((state) => state.global.function);

    // Handlers
    const handleEditTextChange = (e, setIndex, textIndex) => {
        dispatch(updateSelectedGoal({setIndex, textIndex, newText: e.target.value}));
    };
    const handleDeselect = (promptId, event) => {
        event.stopPropagation();
        dispatch(unselectSetID(promptId));
    }

    return (
            <Box sx={{flexGrow: 1, overflowY: 'auto', height: '100%' }}>
              {selectedSets
              .filter(item => item.promptActionType === codeFunction)
              .map((item, index) => {
                return (
                  <>
                  <Accordion key={index} disableGutters={true} defaultExpanded={true} sx={{boxShadow: 'none'}}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{color: theme.palette.font.main}} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{ 
                        ...(accordionHeaderStyle), 
                        backgroundColor: theme.palette.accordion.header,
                        '& .MuiAccordionSummary-content': {
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }
                      }}
                    >
                      <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
                        <Typography sx={{...(fonttypeHeaderstyle), color: theme.palette.font.main, maxWidth: '85%', overflow: 'hidden', textOverflow: 'ellipsis'}}>{item.promptTitle}</Typography> 
                        <IconButton onClick={(event) => handleDeselect(item.id, event)} size="small" className="iconButton" sx={{ fontSize: '15px' }}>
                          <DeleteOutlineOutlinedIcon fontSize="extra-small"/>
                        </IconButton>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{backgroundColor: theme.palette.accordion.content, transition: 'none', paddingTop: '7x', paddingBottom: '7px'}}>
                      {item.promptText.map((text, i) => (
                        <React.Fragment key={i}> 
                        <GlobalStyle bgColor={theme.palette.square.background} />
                        <Box sx={{...(rowBoxStyle)}}>
                        <Tooltip title={text} enterDelay={1500}>
                          <Box sx={{...(textBoxStyle)}}>
                              <EditText
                                key={`${index}-${i}`}
                                name={`textbox-${index}-${i}`}
                                value={text}
                                onChange={(e) => handleEditTextChange(e, index, i)}
                                inputClassName='editinput'
                                style={{
                                  ...(fonttypeStyle),
                                  color: theme.palette.font.main,
                                  display: 'flex',
                                  margin: '0',
                                  paddingLeft: '0px',
                                  alignItems: 'center',
                                  cursor: 'text', 
                                  border: 'none',
                                }}
                              />
                          </Box>
                          </Tooltip>
                        </Box>
                        </React.Fragment>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                  </>
                )
              })}
            </Box>
     
    )
}

export default SelectedSet;