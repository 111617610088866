import ApiConfig from "./ApiConfig.json";
import axios from 'axios';
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    systemData: [],
    filteredData: [],
};

export const dataSlice = createSlice({
    name: "api",
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
            state.filteredData = action.payload;
        },
        setSystemData: (state, action) => {
            state.systemData = action.payload;
        },
        setFilteredData: (state, action) => {
            state.filteredData = action.payload;
        },
    }
})

export const {
    setData,
    setSystemData,
    setFilteredData,
} = dataSlice.actions;

export default dataSlice.reducer;

export const fetchData = async function (userId, promptAPIservice, dispatch) {
    try {
        const res = await promptAPIservice.promptListUser(userId);
        dispatch(setData(res.data));
    } catch (error) {
        console.error(error);
    }
}

export const fetchSystemData = async function (userId, promptAPIservice, dispatch) {
    try {
        const res = await promptAPIservice.promptListSystem(userId);
        dispatch(setSystemData(res.data));
    } catch (error) {
        console.error(error);
    }
}

class PromptAPIservice {
    promptListUser = async (userId) => {
        try {
            const url = ApiConfig.baseurl+ ApiConfig.list + userId + "?Subscription-Key=" + ApiConfig.apiM;
            const headers = {
                'Authorization': sessionStorage.getItem('accessToken'),
            }
            const response = await axios.get(url, { headers });
            return response;
        } catch (error) {
            console.error(error);
        }
    };

    promptListSystem = async () => {
        try {
            const url = ApiConfig.baseurl + ApiConfig.list + ApiConfig.system + "?Subscription-Key=" + ApiConfig.apiM;
            const headers = {
                'Authorization': sessionStorage.getItem('accessToken'),
            }
            const response = await axios.get(url, { headers });
            return response;
        } catch (error) {
            console.error(error);
        }
    };

    promptCreate = async (userId, data) => {
        try {
            const url = ApiConfig.baseurl + ApiConfig.create + userId + "&Subscription-Key=" + ApiConfig.apiM;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': sessionStorage.getItem('accessToken'),
            }
            const response = await axios.post(url, data, { headers });
            fetchData();
            return response;
        } catch (error) {
            console.error(error);
        }
    };

    promptUpdate = async (data) => {
        try {
            const url = ApiConfig.baseurl + ApiConfig.update + "?Subscription-Key=" + ApiConfig.apiM;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': sessionStorage.getItem('accessToken'),
            }
            const response = await axios.put(url, data, { headers });
            fetchData();
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    promptClone = async (promptId, userId) => {
        try {
            const url = ApiConfig.baseurl + ApiConfig.clone + promptId + "?userId=" + userId + "&Subscription-Key=" + ApiConfig.apiM;
            const headers = {
                'Authorization': sessionStorage.getItem('accessToken'),
            }
            const response = await axios.get(url, { headers });
            fetchData();
            return response;
        } catch (error) {
            console.error(error);
            // throw new Error("Cloning failed due to an error");
        }
    }

    promptDelete = async (promptId) => {
        try {
            const url = ApiConfig.baseurl +  ApiConfig.delete + promptId + "?Subscription-Key=" + ApiConfig.apiM;
            const headers = {
                'Authorization': sessionStorage.getItem('accessToken'),
            }
            const response = await axios.delete(url, { headers });            
            fetchData();
            return response;
        } catch (error) {
            console.error(error);
            // throw new Error("Cloning deleting due to an error");
        }
    };

    codeOptimize = async (data) => {
        try {
            const url = ApiConfig.baseurl + ApiConfig.optimize + "?Subscription-Key=" + ApiConfig.apiM;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': sessionStorage.getItem('accessToken'),
            }
            const response = await axios.post(url, data, { headers });
            return response;
        } catch (error) {
            console.error(error);
        }
    };

    codeDocumentation = async (data) => {
        try {
            data.model = "gpt-3.5-turbo-16k";
            const url = ApiConfig.baseurl + ApiConfig.documentation + "?Subscription-Key=" + ApiConfig.apiM;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': sessionStorage.getItem('accessToken'),
            }
            const response = await axios.post(url, data, { headers });
            return response;
        } catch (error) {
            console.error(error);
        }
    };

    jobCreate = async (formdata) => {
        try {
            const url = ApiConfig.baseurl + ApiConfig.createJob + "?Subscription-Key=" + ApiConfig.apiM;
            const headers = {
                'Content-Type': 'multipart/form-data',
                'Authorization': sessionStorage.getItem('accessToken'),
            }
            const response = await axios.post(url, formdata, { headers });
            return response;
        } catch (error) {
            console.error(error);
        }
    };

    jobList = async (userId) => {
        try {
            const url = ApiConfig.baseurl + ApiConfig.jobList + userId + "&Subscription-Key=" + ApiConfig.apiM;
            const headers = {
                'Authorization': sessionStorage.getItem('accessToken'),
            }
            const response = await axios.get(url, { headers });
            return response;
        } catch (error) {
            console.error(error);
        }
    };

    fileList = async (jobId) => {
        try {
            const url = ApiConfig.baseurl + ApiConfig.fileList + jobId + "&Subscription-Key=" + ApiConfig.apiM;
            const headers = {
                'Authorization': sessionStorage.getItem('accessToken'),
            }
            const response = await axios.get(url, { headers });
            return response;
        } catch (error) {
            console.error(error);
        }
    };

    downloadJob = (jobId, jobName) => {
        axios({
          url : ApiConfig.baseurl + ApiConfig.download + jobId + "&Subscription-Key=" + ApiConfig.apiM,
          method: 'GET',
          responseType: 'blob',
          headers : {
            'Authorization': sessionStorage.getItem('accessToken'),
            } 
        })
      
          .then((response) => {
            // Create a temporary URL object
            const url = window.URL.createObjectURL(new Blob([response.data]));
    
            // Create a temporary link element
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', jobName+'.zip'); // Set the desired file name
    
            // Simulate a click on the link to start the download
            document.body.appendChild(link);
            link.click();
    
            // Clean up the temporary objects
            link.remove();
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.error('Error:', error);
          });
     }

     abortJob = async (jobId) => {
        try {
            const url = ApiConfig.baseurl + ApiConfig.abortJob + jobId + "&Subscription-Key=" + ApiConfig.apiM;
            const headers = {
                'Authorization': sessionStorage.getItem('accessToken'),
            }
            const response = await axios.get(url, { headers });
            return response;
        } catch (error) {
            console.error(error);
        }
    };
}
export {
    PromptAPIservice
}
