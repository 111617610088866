import * as React from 'react';
import { useState, useEffect, useContext, useRef } from 'react';
import { Paper, Dialog, Container, IconButton, Typography, MenuItem, FormControl, Select, useTheme } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { AlertContext } from '../../AlertContext.jsx';
import CodeEditor from './CodeEditor.jsx';
import { userPrompt, header, content, fileUpload, selectPosition, selectForm } from './DialogBoxStyles.jsx';
import styles from '../../theme.jsx';
import { setInputLang, setInputCode, toggleFullscreenOutput } from '../../state/index.jsx';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { setOutputLang } from '../../state/index.jsx';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

function CodeEditDialogBox() {
  // Styling
  const theme = useTheme();
  const userPromptStyle = userPrompt();
  const headerStyle = header();
  const contentStyle = content();
  const fileUploadStyle = fileUpload();
  const selectPositionStyle = selectPosition();
  const selectFormStyle = selectForm();

  // Initializations
  const dispatch = useDispatch();
  const outputCode = useSelector(state => state.global.outputCode);
  const [editor, setEditor] = useState(null);
  const code_function = useSelector(state => state.global.function);
  const { setAlert } = useContext(AlertContext);
  const inputCode = useSelector((state) => state.global.inputCode);
  const { function: functionValue, inputLang: inputLang, outputLang: outputLang } = useSelector((state) => state.global);
  const fileInputRef = useRef(null);
  const [code, setCode] = useState(inputCode);
  const isFullscreenOutput = useSelector((state) => state.global.fullscreenOutput);
  const extensions = {
    "python": ".py",
    "javascript": ".js,.jsx",
    "java": ".java",
    "pyspark": ".py"
  }

  useEffect(() => {
    setCode(outputCode);
  }, [outputCode]);

  // this line to check if the outputCode has any content
  const isOutputCodeEmpty = !outputCode.trim();

  // Handlers
  const handleLanguage = (event) => {
    dispatch(setOutputLang(event.target.value));
  };
  const handleEditorReady = (editorInstance) => {
    setEditor(editorInstance);
  }
  const handleDownload = () => {
    if (!editor) return;

    const content = editor.getValue();
    const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    
    switch (outputLang) {
      case 'python':
        link.download = 'bitwizard_output.py';
        break;
      case 'pyspark':
        link.download = 'bitwizard_output.py';
        break;
      case 'java':
        link.download = 'bitwizard_output.java';
        break;
      case 'javascript':
        link.download = 'bitwizard_output.js';
        break;
      default:
        link.download = 'bitwizard_output.txt';
        break;
    }

    link.click();
    URL.revokeObjectURL(url);
  }

  return (
    <>
      <Dialog 
        open={isFullscreenOutput} 
        onClose={() => dispatch(toggleFullscreenOutput())} 
        fullWidth  
        PaperProps={{ sx: { ...(userPromptStyle) } }}
      >

        <Paper sx={{...(headerStyle), background: theme.palette.square.header}} square >
          <Container>
            <Typography sx={{...(styles.typography), color: theme.palette.font.main, lineHeight: '7.5vh'}}>Output</Typography>
            <IconButton
              onClick={handleDownload}
              size="small"
              className="iconButton"
              sx={{ ...(fileUploadStyle), fontSize: '17px' }}
              disabled={isOutputCodeEmpty} // Disable the button when outputCode is empty
            >
              <FileDownloadOutlinedIcon fontSize="small" />
            </IconButton>
            <FormControl size="small" sx={{...(selectPositionStyle)}}>
              <Select sx={{...(selectFormStyle), color: theme.palette.font.main}} 
                labelId="select-language" id="select-language"
                value={outputLang}
                onChange={handleLanguage}
                disabled={functionValue !== 'code_conversion'}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: '200px',
                      overflow: 'auto',
                    },
                  },
                }}
              >
                <MenuItem value={"python"}>Python3</MenuItem>
                <MenuItem value={"javascript"}>JavaScript</MenuItem>
                <MenuItem value={"java"}>Java</MenuItem>
                <MenuItem value={"pyspark"}>PySpark</MenuItem>
                {code_function === 'code_documentation' && <MenuItem value={"plaintext"}>Text</MenuItem>}
              </Select>
            </FormControl>
          </Container>
        
      </Paper>
      <Paper sx={{...(contentStyle), background: theme.palette.square.background}} square variant="outlined">
        <CodeEditor language={outputLang} code={outputCode} onMount={handleEditorReady} defaultComment="Output" />
      </Paper>
      </Dialog>
    </>
    
  );
}

export default CodeEditDialogBox;