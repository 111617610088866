import React from 'react'
import { Typography, Container, Box, useTheme } from "@mui/material";
import styles from '../../theme.jsx';
import SystemPromptSets from './SystemPromptSets';
import UserPromptSets from './UserPromptSets';
import { useSelector } from 'react-redux';


const PromptSets = () => {
  const theme = useTheme();
  const code_function = useSelector((state) => state.global.function);
  return (
    <div>
    <Box sx={{flexGrow: 1, overflowX: 'hidden', overflowY: 'auto', height: 'calc(80vh - 70px)' }}>
      <Container>
        <Typography sx={{...styles.typography, padding: '5px 0', fontSize: '15px', fontWeight: '500', color: theme.palette.font.main}}>Prompt Sets</Typography>
      </Container>
      <SystemPromptSets />
      {code_function !== "code_documentation" && <UserPromptSets />}
      
    </Box>
    </div>
  )
}

export default PromptSets