import React from 'react';
import { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, Box, useTheme, Typography, IconButton, Divider } from "@mui/material";
import { PromptAPIservice } from '../../PromptAPI/PromptAPIservice';
import CloseIcon from '@mui/icons-material/Close';
import { rowFiles } from './BatchStyles';


const FileProgress = ({ open, setOpen, jobId }) => {

    const theme = useTheme();
    const promptAPIservice = new PromptAPIservice();
    const [events, setEvents] = useState([]);
    const rowFilesStyles = rowFiles();

    useEffect(() => {
        fetchEvents(jobId);
    }, [open]);

    const fetchEvents = async (jobId) => {
        try {
            const res = await promptAPIservice.fileList(jobId);
            setEvents(res.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };


    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                PaperProps={{ sx: { width: "40%", maxHeight: "470px" } }}
            >

                <DialogTitle sx={{ fontSize: 18, fontFamily: 'poppins', fontWeight: '400', marginTop: '-3px', padding: 2, paddingBottom: 0, paddingLeft: 3 }}>File Progress
                    <IconButton className="iconButton" sx={{ fontSize: '15px', marginTop: '-3px', float: 'right' }}
                        onClick={() => setOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <Box sx={{ margin: '0px 20px 0px 20px', paddingBottom: '10px' }}>
                    <Typography sx={{ fontFamily: 'inter', fontSize: '13px', lineHeight: '20px', padding: 0 }}>Total Files: <span><strong>{events.length}</strong></span></Typography>
                    <Divider sx={{ marginBottom: '5px', marginTop: '5px' }}></Divider>
                    <Box sx={{ color: theme.palette.text.primary, maxHeight: 300, overflowY: 'auto' }}>
                        <Box>

                            {events.map((item, index) => (
                                <>
                                    <Typography sx={{ fontFamily: 'inter', fontSize: '13px', lineHeight: '20px', padding: 0 }}>Filename: {item.fileName}</Typography>
                                    {item.fileStatus == 'COMPLETE' ? (<Typography sx={{ fontFamily: 'inter', fontSize: '13px', lineHeight: '20px', padding: 0, color: 'green' }}>Status: <span><strong>{item.fileStatus}</strong></span></Typography>) : (<Typography sx={{ fontFamily: 'inter', fontSize: '13px', lineHeight: '20px', padding: 0, color: 'red' }}>Status: <span><strong>{item.fileStatus}</strong></span></Typography>)}
                                    <Typography sx={{ fontFamily: 'inter', fontSize: '13px', lineHeight: '20px', padding: 0 }}>Message: {item.fileMessage}</Typography>
                                    <Divider sx={{ marginBottom: '5px', marginTop: '5px' }}></Divider>
                                </>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </>
    )
}

export default FileProgress
