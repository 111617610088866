import React from 'react'
import { Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Container, useTheme } from "@mui/material";
import { radio, fontsize, fonttype } from './SidebarStyles';
import { useDispatch } from 'react-redux';
import { setFunction, setOutputLang } from '../../state';
import { useNavigate } from 'react-router-dom';

const RadioButtons = () => {
  // Styling
  const theme = useTheme();
  const radioStyle = radio();
  const fontsizeStyle = fontsize();
  const fonttypeStyle = fonttype(theme);
  const navigate = useNavigate();

  // Initializations
  const dispatch = useDispatch();

  // Handlers
  const handleChange = (event) => {
    dispatch(setFunction(event.target.value));
    if(event.target.value != "code_optimization" ) {
      navigate('/home');
    }
  };
  
  return (
    <div >
      <Container sx={{paddingTop: '16px', paddingBottom:'6px'}}>
        <FormControl>
          <FormLabel id="code-function" />
          <RadioGroup
            aria-labelledby="code-function"
            defaultValue="code_optimization"
            name="code-function-group"
            onChange={handleChange}
          >
            <FormControlLabel value="code_optimization" control={<Radio sx={radioStyle} />} label={<Typography sx={{...(fonttypeStyle), color: theme.palette.font.main}}>Optimization</Typography>} sx={fontsizeStyle} />
            <FormControlLabel value="code_documentation" control={<Radio sx={radioStyle} />} label={<Typography sx={{...(fonttypeStyle), color: theme.palette.font.main}}>Documentation</Typography>} sx={fontsizeStyle} />
            {/* <FormControlLabel value="code_generation" control={<Radio sx={radioStyle} />} label={<Typography sx={{...(fonttypeStyle), color: theme.palette.font.main}}>Generation</Typography>} sx={fontsizeStyle} />
            <FormControlLabel value="code_conversion" control={<Radio sx={radioStyle} />} label={<Typography sx={{...(fonttypeStyle), color: theme.palette.font.main}}>Conversion</Typography>} sx={fontsizeStyle} /> */}
          </RadioGroup>
        </FormControl>
      </Container>
    </div>
  )
}

export default RadioButtons

